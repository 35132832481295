<template>
    <div>
    <transition name="transitionRouter">
        <router-view />
    </transition>
    </div>
</template>
<script>
export default {
}
</script>